import React from "react";
import Moment from "react-moment";
import 'moment-timezone';
import { useTranslation } from "react-i18next";

const TimeDisplay = ({dateTime, classNameType=""}) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;

    const timeZoneMoment = (locale) => {
        console.log(locale);
        switch(locale) {
            case "se":
                return "Europe/Stockholm";
            case "no":
                return "Europe/Oslo";
            case "fi":
                return "Europe/Helsinki";
            case "dk":
                return "Europe/Copenhagen";
        }
    }
    
    return (<Moment tz={timeZoneMoment(locale)} className={classNameType} format="HH.mm">{dateTime}</Moment>);
}

export default TimeDisplay;