import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../Store";

const Header = ({ productId = 0 }) => {
  const { casesStore } = useStore();

  let sProductId = casesStore.conceptId;

  if (sProductId == null) {
    sProductId = productId;
  }
  return (
    <div id="Header">
      <div id="HeaderContainerPolestar">
        <div className="polestar-header-name-1">Polestar</div>
        <div className="polestar-header-name-2">Assistance
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
