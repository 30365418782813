import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../Store";
import ETAcomponent from "./ETAcomponent";
import CallButtonModel from "../../CallButtonModel";

const InfoContainer = () => {
  const { casesStore } = useStore();
  const { t, i18n } = useTranslation();

  let sServiceContact = casesStore.serviceContactInfo;
  const sProductId = casesStore.conceptId;
  let sLatestEvent = casesStore.latestEvent;
  const locale = i18n.language;
  setInterval(() => {
    const sServiceContactT = casesStore.serviceContactInfo;
    let sProductIdT = casesStore.conceptId;
    const sLatestEventT = casesStore.latestEvent;
    if (sServiceContactT !== sServiceContact) {
      sServiceContact = sServiceContactT;
    }

    if (sProductIdT !== sProductId) {
      sProductIdT = sProductId;
    }

    if (sLatestEventT !== sLatestEvent) {
      sLatestEvent = sLatestEventT;
    }
  }, 10000);
  if (sLatestEvent == null) {
    let telNumber = "";
    if (locale === "se") {
      telNumber = "tel:020-100100";
    }
    return (
      <div id="info-container">
        <div id="error-warning">{t("error2")}</div>
        <div id="contact-page-button"></div>
        <div id="info-container-info">
          <div className={`info-container-contact-info-call-button p-${sProductId}`}>
            <a href={telNumber}>{t("ContactText")}</a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="info-container">
      <div id="info-container-info">
        <div className="info-container-info-block">
          <div className="info-container-contact-info">
            <div className="info-container-estimation">
            </div>
            <div className={`info-container-contact-info-ETA product-font-${sProductId}`}>
              {t("ETA")}
              <ETAcomponent />
            </div>
            <div className="clear-both"></div>
          </div>
        </div>

        <CallButtonModel product={sProductId} locale={locale}/>
      </div>
    </div>
  );
};

export default observer(InfoContainer);
