const no = {
  title: 'Kontakt',
  welcome1: "Bekreftelse",
  welcome2: "Skriv inn personlig kode som vi sendte deg via SMS",
  statuseventcontainerheader: "Registrering",
  statuscontainerevent0: 'Unknown',
  statuscontainerevent1: 'Saken din er registrert',
  statuscontainerevent2: 'Forespørsel om veihjelp er mottatt',
  statuscontainerevent3: 'Bergingsbilen har mottatt oppdraget',
  statuscontainerevent4: 'Bergingsbilen er på vei',
  statuscontainerevent5: 'Bergingsbilen er framme',
  statuscontainerevent6: 'Ferdig på plassen',
  statuscontainerevent7: 'Bergingsbilen starter transport til avleveringsplass',
  statuscontainerevent8: 'Ferdig på avleveringsplassen',
  statuscontainerevent9: 'Oppdrag avsluttet',
  statuscontainerevent10: 'Kansellert',
  statuscontainerevent11: 'Avbrutt',
  statuscontainer1: "Berging",
  EventsTitle: "Händelser",
  error1: 'Feil PIN, prøv igjen',
  error2: 'Noe gikk galt. Vi jobber for å prøve å løse problemet. Prøv igjen senere',
  ETA: '',
  ETAtext: '{{eta}} min',
  ETAtextH: "{{etah}} t {{eta}} min",
  ETAtextCountdownCompleted: "Kommer snart",
  ContactText: 'Kontakt redningsmann',
  ContactPageHeader1: 'Kontakt alarmsentralen',
  ContactPageHeader2: 'Kontakt redningsmann',
  ContactPageNumber1: '+4721492400 ',
  ContactPageNumber2: '',
  ChatBotButton: "Anmäl din skada här",
  ChatBotHeader: "Här kan du anmäla din skada medan du väntar på bärgningsbilen ",
  ContactTextPolestar: 'Ring veihjelp',
  StatusContainerHeaderPolestar: 'Min pågående sak',
  EventListHeaderPolestar: "Min sak",
  PolestarContactHeader: "Trenger du hjelp?",
  PolestarContactNumber: 'tel:+4780010211'
}
export default no;