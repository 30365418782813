import axios, { Method, AxiosResponse, AxiosError } from "axios";
import { Case } from "../api/dataModel";
import { RootStore } from "../Store";

export class ApiClient {
    private apiAddress: string;
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.apiAddress = process.env.REACT_APP_API_URL;
        this.rootStore = rootStore;
    }

    get isAuthorized() {
        return this.rootStore.pinStore.isPinValid === true;
    }

    private async request<TResponse>(method: Method, relativeUrl: string)
        : Promise<{ success: boolean, response?: AxiosResponse<TResponse>, error?: AxiosError<TResponse> }> {
        const loadingName = `${method}-request:::${relativeUrl}`;
        try {
            this.rootStore.loadingStore.startLoading(loadingName);
            let result = await axios.request<TResponse, AxiosResponse<TResponse>>({
                method,
                url: new URL(relativeUrl, this.apiAddress).href,
                headers: {
                    "Content-Type": "application/json",
                    "pin": this.rootStore.pinStore.pinValue
                }
            });
            return { success: true, response: result };
        }
        catch (e) {
            var axiosError = e as AxiosError<TResponse>;
            if (axiosError.isAxiosError !== undefined) {
                return { success: false, error: axiosError };
            }
            throw e;
        }
        finally {
            this.rootStore.loadingStore.stopLoading(loadingName);
        }
    }

    private async get<TResponse>(relativeUrl: string) {
        return await this.request<TResponse>("GET", relativeUrl);
    }


    getCase = async (caseId: number): Promise<{ isError: boolean, cases?: 
        Case, errorType?: "ServerError" | "PinError", errorMessage?: string }> => {
            
        var { success, error, response } = await this.get<Case>(`cases/${caseId}`);
        if (success) {
            return {
                isError: false,
                cases: response.data
            };
        }

        return {
            isError: true,
            errorMessage: error.message,
            errorType: error.response.status === 401 ? "PinError" : "ServerError"
        };
    }
}