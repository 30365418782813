export interface Case {
  id: number;
  mobileNoCountry: string;
  incidentDateTime: Date;
  productId: number;
  actions: CaseAction[];
}

export interface CaseAction {
  id: number;
  createdDateTime: Date;
  caseActionType: CaseActionTypeEnum;
  events: CaseActionEvent[];
  serviceContacts: ServiceContact[];
}

export enum CaseActionTypeEnum {
  unknown = 0,
  towing = 1,
  notification = 2,
}

export interface CaseActionEvent {
  id: number;
  eventType: CaseActionEventTypeEnum;
  eventDateTime: Date;
  etaMinutes: number;
  locations: Location[];
}

export enum CaseActionEventTypeEnum {
  Unknown = 0,
  CaseRegistered = 1,
  TowingRequested = 2,
  DispatchedAccepted = 3,
  OnTheWay = 4,
  ArrivalAtIncidentSite = 5,
  ReadyAtIncidentSite = 6,
  StartTransportation = 7,
  ReadyAtDestination = 8,
  ServiceExecuted = 9,
  Cancelled = 10,
  Interrupted = 11,
}

export interface ServiceContact {
  id: number;
  contactType: ServiceContactTypeEnum;
  name: string;
  phone: string;
}

export enum ServiceContactTypeEnum {
  Towing = 0,
}

export interface Location {
  id: number;
  locationType: LocationTypeEnum;
  latitude: number;
  longitude: number;
  street: string;
  zip: string;
  city: string;
  locationName: string;
  createdDateTime: Date;
}

export enum LocationTypeEnum {
  Unknown = 0,
  BreakdownLocation = 1,
  PickupLocation = 2,
  DropoffLocation = 3,
}
