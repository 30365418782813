import React from "react";
import InfoContainerPolestar from "../InfoContainer/InfoContainerPolestar";
import EventsComponentPolestar from "../EventsPolestar";
// import MapContainer from "../MapContainer/MapContainer";
import { observer } from "mobx-react-lite";

const StatusContainer = () => {
  return (
    <div>
      <div id="StatusContainerMain">
        <EventsComponentPolestar />
      </div>
      {/* <MapContainer /> */}
      <InfoContainerPolestar />
    </div>
  );
};

export default observer(StatusContainer);
