import { makeAutoObservable } from "mobx";

export default class LoadingStore {
    loadingMap = new Map<string, number>();

    constructor() {
        // without autobind can only use arrow function
        makeAutoObservable(this, {}, { autoBind: true });
    }

    get isLoading() {
        return this.loadingMap.size !== 0;
    }

    startLoading(name: string) {
        const newCounter = (this.loadingMap.get(name) ?? 0) + 1;
        this.loadingMap.set(name, newCounter);
    }

    stopLoading(name: string) {
        const newCounter = (this.loadingMap.get(name) ?? 0) - 1;
        if (newCounter > 0) {
            this.loadingMap.set(name, newCounter);
        } else {
            this.loadingMap.delete(name);
        }
    }

    clearAllLoadings() {
        this.loadingMap = new Map<string, number>();
    }
}