const en = {
  title: "Contact",
  welcome1: "VerifieringEN",
  // welcome2: "Skriv in den personliga koden som vi skickade till dig via SMS.EN",
  welcome2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices habitasse amet mi enim nunc a nunc, sem quam. Nunc, ipsum vivamus fringilla vitae. Cras lorem nunc, arcu viverra.",
  statuscontainerevent0: "Unknown",
  statuscontainerevent1: "Case Registered",
  statuscontainerevent2: "Request for assistance received",
  statuscontainerevent3: "The towing truck has received the assignment",
  statuscontainerevent4: "Your tow truck is on its way, is expected to be in place in 17 minutes",
  statuscontainerevent5: "Towing car on sight",
  statuscontainerevent6: "Ready at sight",
  statuscontainerevent7: "Towing started",
  statuscontainerevent8: "Ready at destination",
  statuscontainerevent9: "Towing completed",
  statuscontainerevent10: "Cancelled",
  statuscontainerevent11: "Interrupted",
  error1: 'Wrong code - please try again',
  error2: 'Something went wrong, we will try to solve it - please try again later',
  ETA: '',
  ETAtext: '{{eta}} min',
  ETAtextH: '{{etah}} h {{eta}} min',
  ETAZero: 'arrived',
  ETAtextCountdownCompleted: "Arriving soon",
  ContactText: 'Contact the tower',
  ContactPageHeader1: 'Contact the alarm central',
  ContactPageHeader2:'Contact the tower',
  ContactPageNumber1: '020-100100',
  ContactPageNumber2: '',
  ContactTextPolestar: 'Call assistance',
};
export default en;
