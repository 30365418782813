import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../Store";

const ContactPageButton = () => {
  const { casesStore } = useStore();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const sProductId = casesStore.conceptId;
  const locale = i18n.language;

  if (sProductId == null) {
    return <div id="InfoContainer-main"></div>;
  }
  const handleClick = (e: any) => {
    navigate(`/${sProductId}/contact/home`);
  };

  return (
    <div className={`contact-page-button-${sProductId}-${locale}`}>
      <div onClick={handleClick} className="header-logo-second"></div>
      <div className={`header-contact-entry product-font-${sProductId}`}>
        {t("title")}
      </div>
    </div>
  );
};

export default observer(ContactPageButton);
