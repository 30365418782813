import { makeAutoObservable } from "mobx";

const digitsAmount = 4;

export interface IPinStore {
    digits: (number | string | null)[];
    currentDigitIdx: number;
    isPinValid: boolean | null;
}

export default class PinStore implements IPinStore {
    digits: (number | string | null)[] = Array(digitsAmount).fill(null);
    currentDigitIdx: number = 0;
    isPinValid: boolean | null = null;

    constructor() {
        // without autobind can only use arrow function
        makeAutoObservable(this, {}, { autoBind: true });
    }

    get pinValue() {
        return this.digits.join('');
    }

    get isPinComplete() {
        return this.digits.every(d => d !== null);
    }

    clearPinButKeepValidityState() {
        this.currentDigitIdx = 0;
        this.digits = Array(digitsAmount).fill(null);
    }

    enterOrRemoveDigit(digitIndex: number, digit: number | string | null) {
        // clear all digits after last entered/removed one
        this.digits = this.digits.slice(0, digitIndex)
            .concat(digit)
            .concat(Array(Math.max(digitsAmount - digitIndex - 1, 0)).fill(null));
        // if adding digit, move forward, if removing - move backwards
        var nextDigit = digitIndex + (digit === null ? -1 : 1);
        this.currentDigitIdx = Math.min(Math.max(nextDigit, 0), digitsAmount - 1);

        this.isPinValid = null;
    }

    setPinValidity(isValid: boolean) {
        this.isPinValid = isValid;
    }

    setCurrentDigitIdx(digitIndex: number) {
        this.currentDigitIdx = digitIndex;
    }
}