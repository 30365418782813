import MapDefaults from "./Models/MapDefaults";
import CountryConfig from "./Models/CountryConfig";
import { CountryEnum } from "./Models/CountryEnum";

export default class CountryConfigs {
    private static Default: CountryConfig = {
        name: "Other",
        nameInLocalLanguage: "Other",
        code: "",
        code3: "",
        enumValue: CountryEnum.Other,
        translationLocale: "en",
        // Europe
        mapDefaults: {
            latitude: 54.00,
            longitude: 14.00,
            zoom: 4
        }
    };

    static Sweden: CountryConfig = {
        ...CountryConfigs.Default,
        name: "Sweden",
        nameInLocalLanguage: "Sverige",
        code: "SE",
        code3: "SWE",
        enumValue: CountryEnum.Sweden,
        translationLocale: "se",
        mapDefaults: {
            latitude: 63.00,
            longitude: 16.00,
            zoom: 5
        }
    };

    static Norway: CountryConfig = {
        ...CountryConfigs.Default,
        name: "Norway",
        nameInLocalLanguage: "Norge",
        code: "NO",
        code3: "NOR",
        enumValue: CountryEnum.Norway,
        translationLocale: "no",
        mapDefaults: {
            latitude: 65.00,
            longitude: 11.00,
            zoom: 5
        }
    };

    static Denmark: CountryConfig = {
        ...CountryConfigs.Default,
        name: "Denmark",
        nameInLocalLanguage: "Danmark",
        code: "DK",
        code3: "DNK",
        enumValue: CountryEnum.Denmark,
        translationLocale: "dk",
        mapDefaults: {
            latitude: 56.00,
            longitude: 10.00,
            zoom: 7
        }
    };

    static Finland: CountryConfig = {
        ...CountryConfigs.Default,
        name: "Finland",
        nameInLocalLanguage: "Suomi",
        code: "FI",
        code3: "FIN",
        enumValue: CountryEnum.Finland,
        translationLocale: "fi",
        mapDefaults: {
            latitude: 65.00,
            longitude: 26.00,
            zoom: 5
        }
    };

    static Other: CountryConfig = {
        ...CountryConfigs.Default,
    };

    static AllCountries: CountryConfig[] = [
        CountryConfigs.Sweden,
        CountryConfigs.Norway,
        CountryConfigs.Denmark,
        CountryConfigs.Finland,

        CountryConfigs.Other
    ];

    static getCountryByCode(code: string): CountryConfig {
        var result = this.AllCountries.find(c => c.code.toLowerCase() === code?.toLowerCase() || c.code3.toLowerCase() === code?.toLowerCase());
        return result ? result : CountryConfigs.Other;
    }

    static getCountryByEnum(enumValue: CountryEnum): CountryConfig {
        var result = this.AllCountries.find(c => c.enumValue === enumValue);
        return result ? result : CountryConfigs.Other;
    }
}