import se from "../../components/Translations/se";
import no from "../../components/Translations/no";
import fi from "../../components/Translations/fi";
import dk from "../../components/Translations/dk";
import en from "../../components/Translations/en";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { getCountry } from "../CountryDetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: "subdomainDetector",
    lookup(options) {// get language from domain
        return getCountry().translationLocale;
    },
    cacheUserLanguage(lng, options) {
    },
});

const defaultNamespace = "defaultNamespace";

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: { se, no, fi, dk, en },
        fallbackLng: "no",
        defaultNS: defaultNamespace,
        detection: {
            order: ["subdomainDetector"]
        }
    });
i18n.addResourceBundle('se', defaultNamespace, se);
i18n.addResourceBundle('no', defaultNamespace, no);
i18n.addResourceBundle('fi', defaultNamespace, fi);
i18n.addResourceBundle('dk', defaultNamespace, dk);
i18n.addResourceBundle('en', defaultNamespace, en);

export default i18n;