import React, { Component } from "react";
import "./App.scss";
import "./branding.scss";
import "./source-css/main-css.scss"
import "./source-css/branding.scss"
import "./source-css/structures.scss"
import "./components/Translations";
import PageLayout from "./components/PageLayout";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import PinRoute from "./Routes/Pin";
import ContactPage from "./components/ContactPage/ContactPage";
import NotAuthorized from "./components/ErrorPages/NotAuthorized";
import { StoreProvider } from "./Store";
import SignalrClientComponent from "./components/SignalrClientComponent";
import NCTOMainBody from "./components/Body/NCT/NCTOMainBody";

class App extends Component {
  render() {
    return (
      <div className="App">
        <StoreProvider>
          <SignalrClientComponent />
          <BrowserRouter>
            <Routes>
              <Route path="/:id/assistance/home" element={<PageLayout concept="Other" />} />
              <Route path="/:id/polestar/home" element={<PageLayout concept="Polestar" />} />
              <Route path="/:id/volvia/home" element={<PageLayout concept="Volvia" />} />
              <Route path="/abroad" element={<NCTOMainBody />}/>
              <Route path="/:id/contact/home" element={<ContactPage />} />
              <Route path="/:conceptId/:caseId" element={<PinRoute />} />
              <Route path="/NotAuthorized" element={<NotAuthorized />} />
            </Routes>
          </BrowserRouter>
        </StoreProvider>
      </div>
    );
  }
}

export default App;

