import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { Case } from "../api/dataModel";
import { useStore } from "../Store";

const SignalrClientComponent: React.FC = () => {
    const { casesStore, pinStore, signalrClient } = useStore();

    useEffect(() => {
        (async () => {
            if(casesStore.caseId === null || !pinStore.isPinValid) {
                await signalrClient.closeConnection();
                return;
            }
            
            const hub = await signalrClient.restartConnection();
            hub.on("ReceiveCaseUpdate", (data: Case) => {
                casesStore.setCase(data)
            });
        })();
    }, [casesStore, signalrClient, casesStore.caseId, pinStore.isPinValid]);

    return (<></>);
}
export default observer(SignalrClientComponent);