import { FunctionComponent } from "react";
import { CountryEnum } from "./CountryEnum";
import MapMarkerConfig from "./MapMarkerConfig";


export class ProductConfig {
    productName: string;
    productIds: number[];
    country: CountryEnum;
    contactPhone: string;

    svgLogoComponentFactory: () => { ReactComponent: FunctionComponent<any>; };

    mapMarkerForCar: MapMarkerConfig;
    mapMarkerForService: MapMarkerConfig;
    mapMarkerForTowTruck: MapMarkerConfig;
}

export class ProductConfigWithCountries extends ProductConfig {
    configsPerCountry: Map<CountryEnum, ProductConfig>;
}