import { FunctionComponent } from "react";
import CountryConfigs from "../CountryConfigs";
import { CountryEnum } from "../Models/CountryEnum";
import { ProductConfigWithCountries, ProductConfig } from "../Models/ProductConfig";
import MapMarkerConfig from "../Models/MapMarkerConfig";


export class ProductConfigBuilder {
    private result: ProductConfigWithCountries;

    constructor(name: string, ids: number[] | number) {
        this.result = new ProductConfigWithCountries();

        this.result.productName = name;
        this.result.productIds = Array.isArray(ids) ? ids : [ids];
        this.result.configsPerCountry = new Map<CountryEnum, ProductConfig>();
    }

    withContactPhone(phone: string) {
        return this.privateWith(value => value.contactPhone = phone);
    }

    withSvgLogoComponentFactory(factory: () => { ReactComponent: FunctionComponent<any>; }) {
        return this.privateWith(value => value.svgLogoComponentFactory = factory);
    }

    withMapMarkerForCar(mapMarkerForCar: MapMarkerConfig) {
        return this.privateWith(value => value.mapMarkerForCar = mapMarkerForCar);
    }

    withMapMarkerForService(mapMarkerForService: MapMarkerConfig) {
        return this.privateWith(value => value.mapMarkerForService = mapMarkerForService);
    }

    withMapMarkerForTowTruck(mapMarkerForTowTruck: MapMarkerConfig) {
        return this.privateWith(value => value.mapMarkerForTowTruck = mapMarkerForTowTruck);
    }

    with(fn: (builder: ProductConfigBuilder) => ProductConfigBuilder) {
        return fn(this);
    }

    build(): ProductConfigWithCountries {
        this.validate();
        this.normalize();
        return this.result;
    }

    private validate(): void {
        if (this.result.productName === undefined) {
            throw new Error("Product name was not set");
        }
        if (this.result.productIds === undefined) {
            throw new Error("Product ids were not set");
        }
        if (this.result.contactPhone === undefined) {
            throw new Error("Default contact phone was not set");
        }
        if (this.result.svgLogoComponentFactory === undefined) {
            throw new Error("Default SVG logo component factory was not set");
        }
        if (this.result.mapMarkerForCar === undefined) {
            throw new Error("Default map marker for car was not set");
        }
        if (this.result.mapMarkerForService === undefined) {
            throw new Error("Default map marker for service was not set");
        }
        if (this.result.mapMarkerForTowTruck === undefined) {
            throw new Error("Default map marker for tow truck was not set");
        }
    }

    private normalize() {
        CountryConfigs.AllCountries.forEach(country => {
            let config = this.result.configsPerCountry.get(country.enumValue) ?? new ProductConfig();
            config.country = country.enumValue;

            if (config.productName === undefined) {
                config.productName = this.result.productName;
            }
            if (config.productIds === undefined) {
                config.productIds = [...this.result.productIds];
            }
            if (config.contactPhone === undefined) {
                config.contactPhone = this.result.contactPhone;
            }
            if (config.svgLogoComponentFactory === undefined) {
                config.svgLogoComponentFactory = this.result.svgLogoComponentFactory;
            }
            if (config.mapMarkerForCar === undefined) {
                config.mapMarkerForCar = this.result.mapMarkerForCar;
            }
            if (config.mapMarkerForService === undefined) {
                config.mapMarkerForService = this.result.mapMarkerForService;
            }
            if (config.mapMarkerForTowTruck === undefined) {
                config.mapMarkerForTowTruck = this.result.mapMarkerForTowTruck;
            }
            this.result.configsPerCountry.set(country.enumValue, config);
        });
    }

    private privateWith(update: (value: ProductConfig) => void) {
        const nextBuilder = (countries: CountryEnum[]) => {
            countries.forEach(country => {
                let newValue = {
                    ...this.result.configsPerCountry.get(country),
                    country: country
                };
                update(newValue);
                this.result.configsPerCountry.set(country, newValue);
            });
            return this;
        };
        const chainUpdate = (nextUpdate: (value: ProductConfig) => void) => {
            return this.privateWith(value => {
                update(value);
                nextUpdate(value);
            });
        };

        return {
            byDefault: () => {
                update(this.result);
                return this;
            },

            forSweden: () => nextBuilder([CountryEnum.Sweden]),
            forNorway: () => nextBuilder([CountryEnum.Norway]),
            forDenmark: () => nextBuilder([CountryEnum.Denmark]),
            forFinland: () => nextBuilder([CountryEnum.Finland]),

            for: nextBuilder,

            andContactPhone: (phone: string) => chainUpdate(value => value.contactPhone = phone),
            andSvgLogoComponentFactory: (factory: () => { ReactComponent: FunctionComponent<any>; }) => chainUpdate(value => value.svgLogoComponentFactory = factory),
            andMapMarkerForCar: (config: MapMarkerConfig) => chainUpdate(value => value.mapMarkerForCar = config),
            andMapMarkerForService: (config: MapMarkerConfig) => chainUpdate(value => value.mapMarkerForService = config),
            andMapMarkerForTowTruck: (config: MapMarkerConfig) => chainUpdate(value => value.mapMarkerForTowTruck = config)
        };
    }
}
