import { useLocalObservable } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ApiClient } from "../apiClient/ApiClient";
import { SignalrClient } from "../apiClient/SignalrClient";
import CasesStore from "./CasesStore";
import LoadingStore from "./LoadingStore";
import PinStore from "./PinStore"

export class RootStore {
    apiClient: ApiClient;
    signalrClient: SignalrClient;

    pinStore: PinStore;
    casesStore: CasesStore;
    loadingStore: LoadingStore;

    constructor() {
        this.apiClient = new ApiClient(this);
        this.signalrClient = new SignalrClient(this);

        this.pinStore = new PinStore();
        this.loadingStore = new LoadingStore();
        this.casesStore = new CasesStore(this);
    }

    dispose = () => {
        this.signalrClient.closeConnection();
    }
}

const rootStore = new RootStore();

const storeContext = React.createContext<RootStore | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
    const store = useLocalObservable(() => rootStore);

    useEffect(() => {
        return () => {
            store.dispose();
        };
    }, [store]);

    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
}

export default rootStore;