import React from "react";
import 'moment-timezone';
import "./Polestar.css";
import { useStore } from "../../Store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import TimeDisplay from "./TimeDisplay";

const EventsComponentPolestar = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();

  const caseEvents = [];
  const caseEventsETA = [];
  const caseEvents2 = [];
  const caseEvents2ETA = [];

  if (casesStore.events != null) {
    casesStore.events.forEach((item) => {
      if (item.eventType === 1) {
        caseEvents.push(
          <li key={"eventContainer" + item.eventType} className="status-container-event-polestar">
            <div className="status-container-event-polestar-1"> 
              <TimeDisplay dateTime={item.eventDateTime} classNameType="polestar-events-dot"/>
              - {t(`statuscontainerevent${item.eventType}`)}
            </div>
          </li>
        )
        caseEventsETA.push(
          <div className="status-container-event-polestar-header">
            <TimeDisplay dateTime={item.eventDateTime} /> - {t(`statuscontainerevent${item.eventType}`)}
          </div>
        )
      } else {
        caseEvents2.push(
          <li key={"eventContainer" + item.eventType} className="status-container-event-polestar">
            <div className="status-container-event-polestar-1">
              <TimeDisplay dateTime={item.eventDateTime} /> - {t(`statuscontainerevent${item.eventType}`)}
            </div>
          </li>
        )

        if (caseEvents2ETA.length === 0) {
          caseEvents2ETA.push(
            <div className="status-container-event-polestar-header">
              <TimeDisplay dateTime={item.eventDateTime} /> - {t(`statuscontainerevent${item.eventType}`)}
            </div>
          )
        } else {

          caseEvents2ETA.fill(
            <div className="status-container-event-polestar-header">
              <TimeDisplay dateTime={item.eventDateTime} /> - {t(`statuscontainerevent${item.eventType}`)}
            </div>
          )
        }
      }

    });

    return (
      <div id="status-container-polestar">
        <div className="status-container-header-polestar">
          {t("EventListHeaderPolestar")}
        </div>
        {(() => {
          return (
            <div className="overflow--scroll">
              {caseEvents2ETA}
              <ul className="polestar-events-list">
                {caseEvents2.reverse()}
              </ul>
              {caseEventsETA}
              <ul>
                {caseEvents.reverse()}
              </ul>
            </div>
          )
        })()}
      </div>
    );
  }

  return <div className="loading-bar"></div>;
}

export default observer(EventsComponentPolestar);