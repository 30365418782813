import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useNavigate  } from "react-router-dom";
import StatusContainer from "./Body/StatusContainer/StatusContainer";
import { useEffect } from "react";
import { useStore } from "../Store";
import { observer } from "mobx-react-lite";
import HeaderVolvia from "./Header/HeaderVolvia";
import StatusContainerVolvia from "./Body/StatusContainer/StatusContainerVolvia";
import HeaderPolestar from "./Header/HeaderPolestar";
import StatusContainerPolestar from "./Body/StatusContainer/StatusContainerPolestar";
import NCTOMainBody from "./Body/NCT/NCTOMainBody";

const PageLayout: React.FC<{ concept: "Volvia" | "Polestar" | "Other" | "NCT" }> = ({ concept }) => {
  const { casesStore, pinStore, loadingStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!pinStore.isPinValid || casesStore.errorCause !== null) {
        navigate(`/${casesStore.conceptId}/${casesStore.caseId}`, {state: 500});
      }
    })()
  }, [casesStore.caseId, casesStore.conceptId, casesStore.errorCause, pinStore.isPinValid, navigate]);


  if (concept === "Other") {
    // TODO
    document.body.style.backgroundColor = "#f6f3f0";
  }

  if (loadingStore.isLoading) {
    return <div className="loading-bar"></div>;
  }

  switch (concept) {
    case "Volvia":
      return (
        <div id="LayoutTwoContainer">
          <HeaderVolvia />
          <div id="BodyContent">
            <StatusContainerVolvia />
          </div>
          <Footer />
        </div>
      );
    case "Polestar":
      return (
        <div id="LayoutTwoContainer">
          <HeaderPolestar />
          <div id="BodyContent">
            <StatusContainerPolestar />
          </div>
          <Footer />
        </div>
      );
    case "Other":
      return (
        <div id="LayoutTwoContainer">
          <Header />
          <div id="BodyContent">
            <StatusContainer />
          </div>
          <Footer />
        </div>
      );
      case "NCT": 
        return(
          <div id="LayoutTwoContainer">
          <Header />
            <NCTOMainBody /> 
          <Footer />
        </div>
      );
  }
};

export default observer(PageLayout);
