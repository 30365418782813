import React from "react";
import { useTranslation } from "react-i18next";
import ProductConfigs from "../Configs/ProductConfigs";

const CallButtonModel = ({ product = 1001, locale = "se" }) => {
    const { t } = useTranslation();

    return (
        <div className={`info-container-contact-info-call-button p-${product}`}>
            <a
                className={`info-container-contact-info-call-button-main-${product}`}
                href={`tel:${ProductConfigs.getByIdAndCountry(product, locale).contactPhone}`}>
                {product === 1004
                    ? t("ContactText3")
                    : product === 1003 || product === 1042
                        ? t("ContactText2")
                        : t("ContactText")}
            </a>
        </div>
    );
}

export default CallButtonModel;