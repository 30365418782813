import CountryConfigs from "../Configs/CountryConfigs";
import CountryConfig from "../Configs/Models/CountryConfig";

export function getCountry(): CountryConfig {
    // get language from domain
    var s = window.location.hostname;
    if (s === "localhost") {
        return CountryConfigs.Sweden;

    } else {
        var num = s.match(/\./g).length;
        s = s.split(".")[num];
        if (s === "biz") {
            var c = window.location.hostname.split(".")[1];
            return CountryConfigs.getCountryByCode(c);
        } else {
            return CountryConfigs.getCountryByCode(s);
        }
    }
}