import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../Store";
import Countdown from 'react-countdown';
import moment from "moment";
import { CaseActionEventTypeEnum } from "../../../api/dataModel";

const ETAcomponent = () => {
  const { casesStore } = useStore();
  const {t}=useTranslation();
  var receivedEtaMinutes = casesStore.latestEvent.etaMinutes;
  var localDateNow = moment().toDate();
  const keyword = `eventCompleted${casesStore.caseId}${casesStore.latestEvent.eventType}`
  const keywordToGet = `eventCompleted${casesStore.caseId}${casesStore.latestEvent.eventType-1}`
  const storedCompleted = localStorage.getItem(keywordToGet);

  if((storedCompleted === "Completed" && receivedEtaMinutes === 0) || casesStore.latestEvent.eventType === CaseActionEventTypeEnum.ArrivalAtIncidentSite) {
    return <div>{t("ETAtextCountdownCompleted")}</div>;
  }
  if(receivedEtaMinutes === 0){
    return <div></div>
  }
  else {
    const renderer = ({ hours, minutes, completed }: any) => {
      if ((completed || hours === 0) && minutes === 0) {
        localStorage.setItem(keyword, "Completed")
        return <div>{t("ETAtextCountdownCompleted")}</div>;
      } else if (hours > 0) {
        return <div>{t("ETAtextH", { etah: hours, eta: minutes })}</div>;
      } else {
        return <div>{t("ETAtext", { eta: minutes })}</div>;
      }
    };
  
    if (casesStore.events != null) {
      if (receivedEtaMinutes < 0) {
        return <>{t("ETAtext", { eta: 0 })}</>;
      } else {
        return (          
            <Countdown
            intervalDelay={30000}
            date={localDateNow.getTime() + (receivedEtaMinutes * 60000)}
            renderer={renderer}
            />       
        );
      }
    };
  }
  
  return <div className="loading-bar"></div>;
};
export default observer(ETAcomponent);