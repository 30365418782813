import React from "react";
import EventsComponent from "../Events";
import InfoContainer from "../InfoContainer/InfoContainer";
// import MapContainer from "../MapContainer/MapContainer";
import { observer } from "mobx-react-lite";

const StatusContainer = () => {
  return (
    <div>
      <InfoContainer />
      {/* <MapContainer /> */}
      <div id="StatusContainerMain">
        <EventsComponent />
      </div>
    </div>
  );
};

export default observer(StatusContainer);
