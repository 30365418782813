import React, { useMemo } from "react";
import ProductConfigs from "../Configs/ProductConfigs";
import { useStore } from "../Store";

const LogoModel = ({ productId = 0 }) => {
  const { casesStore } = useStore();

  const SvgLogoComponent = useMemo(() => {
    const countryConfig = casesStore.countryConfig;
    const product = ProductConfigs.getById(productId);

    if(!product || !countryConfig) {
      return null;
    }
    
    var logoFactory = product.configsPerCountry.get(countryConfig.enumValue).svgLogoComponentFactory;
    return logoFactory ? logoFactory().ReactComponent ?? null : null;
  }, [casesStore.countryConfig, productId]);

  return (
    <div className={`header-logo-first-${productId}`}>
      {SvgLogoComponent ? <SvgLogoComponent /> : <div></div>}
    </div>
  );
}

export default LogoModel;