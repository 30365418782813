import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../Store";

const ErrorWarning = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();

  if (casesStore.errorCause === "PinError") {
    return <div id="error-warning">{t("error1")}</div>
  }
  if (casesStore.errorCause === "ServerError") {
    return <div id="error-warning">{t("error2")}</div>
  }
  return <div id="error-warning"></div>;
}

export default observer(ErrorWarning);