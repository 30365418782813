import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../Store";
import ContactPageButton from "../ContactPage/ContactPageButton";
import LogoModel from "../LogoModel";
import i18n from "../Translations";

const Header = ({ productId = 0 }) => {
  const { casesStore } = useStore();
  const locale = i18n.language;

  let sProductId = casesStore.conceptId;

  if (sProductId == null) {
    sProductId = productId;
  }
  return (
    <div id="Header">
      <div id="HeaderContainer">
        <LogoModel productId={sProductId}/>
        <div className={`header-phone-block ${locale}`}>
          <ContactPageButton />
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
