import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../Store";

const InfoContainer = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();

  let sServiceContact = casesStore.serviceContactInfo;
  const sProductId = casesStore.conceptId;
  let sLatestEvent = casesStore.latestEvent;
  setInterval(() => {
    const sServiceContactT = casesStore.serviceContactInfo;
    let sProductIdT = casesStore.conceptId;
    const sLatestEventT = casesStore.latestEvent;
    if (sServiceContactT !== sServiceContact) {
      sServiceContact = sServiceContactT;
    }

    if (sProductIdT !== sProductId) {
      sProductIdT = sProductId;
    }

    if (sLatestEventT !== sLatestEvent) {
      sLatestEvent = sLatestEventT;
    }
  }, 10000);
  if (sLatestEvent == null) {
    return (
      <div id="info-container-polestar"></div>
    );
  }

  return (
    <div id="info-container-polestar">
      <div className="assistance-text-polestar">
        {t("PolestarContactHeader")}
      </div>
      <a href={t("PolestarContactNumber")}>
        <div id="info-container-info-polestar">
          <div id="info-container-polestar-text">
            {t("ContactTextPolestar")}
          </div>
          <div id="info-polestar-logo"></div>
        </div>
      </a>
    </div>
  );
};

export default observer(InfoContainer);
