import { observer } from "mobx-react-lite";
import {FiPhone} from "react-icons/fi"
import React from "react";
import LogoModel from "../../LogoModel";
import i18n from "../../Translations";
import ContactPageButton from "../../ContactPage/ContactPageButton";

const NCTOMainBody = () => {
    const locale = i18n.language;

    return (
        <div id="LayoutTwoContainer">
            <div id="Header">
                <div id="HeaderContainer">
                    <LogoModel productId={1001}/>
                    <div className={`header-phone-block ${locale}`}>
                        <ContactPageButton />
                    </div>
                </div>
            </div>
            <div id="BodyContent">
            <div className={'pr--1001 lc--no'}>
                <div className="container--transparent ">
                  <h1>Roadside assistance</h1>
                </div>

                <section className={'container--base column radius--6'}>
                    <div className="status-container-event-logo-4"></div>
                
                    <h3 className="heading--bold">Tow truck is on it's way</h3>
                    <p>Viking Road Assistance has, on behalf of your insurance company, registered your request for assistance, and has started working on your case.</p>

                    <div className="margin--top-20">
                        <p>Here is what will happen next:</p>
                        <ul className="container-list margin--top-20">
                            <li><p>Viking staff will contact their partner in the area where you are to arrange for your needs of assistance.</p></li>
                            <li><p>Once the details are arranged Viking will contact you on this phone, or the phone number that you have agreed on.</p></li>
                            <li><p>Viking will stay in constant contact with you and follow-up on the ordered activities.</p></li>
                            <li><p>You can get in contact with Viking staff at any time by pressing the link below. A call will be connected.</p></li>
                        </ul>
                    </div>

                    <div className="margin--right-10 margin--top-20">
                        <p>Read more about the process for roadside assistance abroad.</p>
                    </div>
                    
                    
                    <div className={`button button--ifblue radius--6 margin--top-30 margin--top-20`}>
                        <div className="margin--right-10"><FiPhone size={"1.2rem"}/></div>
                        <a href="2345678">
                            Call Viking Road Assistance
                        </a>
                    </div>
                </section>

                <section className="container--transparent">
                    <h3 className="heading--informative">
                        If you think this assistance event will lead to a claim covered by your insurance, we suggest that you file a claim here.
                    </h3>
                    <div className={`button button--ifgray radius--6 margin--top-20`}>
                      <a href="2345678">
                        File a claim
                      </a>
                    </div>

                    <div className="justify--center margin--top-20">
                       <a href="/" className="text--ifblue">Go to if.se</a> 
                    </div>
                </section>
            </div>
        </div>
        </div>      
    );
}

export default observer(NCTOMainBody);