import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../Store";
import LogoModel from "../LogoModel";

const Header = ({ productId = 0 }) => {
  const { casesStore } = useStore();

  let sProductId = casesStore.conceptId;

  if (sProductId == null) {
    sProductId = productId;
  }
  return (
    <div id="Header">
      <div id="HeaderContainerVolvia">
        <LogoModel productId={1003}/>
      </div>
    </div>
  );
};

export default observer(Header);

