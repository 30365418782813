import React from "react";
import InfoContainerVolvia from "../InfoContainer/InfoContainerVolvia";
import EventsComponentVolvia from "../EventsVolvia";
// import MapContainer from "../MapContainer/MapContainer";
import { observer } from "mobx-react-lite";

const StatusContainer = () => {
  return (
    <div>
      <InfoContainerVolvia />
      {/* <MapContainer /> */}
      <div id="status-container-main-volvia">
        <EventsComponentVolvia />
      </div>
    </div>
  );
};

export default observer(StatusContainer);
