import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../Store";
import LogoModel from "../LogoModel";

const ContactPage = () => {
  const { casesStore } = useStore();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const sProductId = casesStore.conceptId;
  const locale = i18n.language;
  if (sProductId == null) {
    return <div className="loading-bar"></div>;
  }

  const handleClick = (e: any) => {
    // go back
    navigate(-1);
  };

  return (
    <div id="ContactPage">
      <div id="Header">
        <div id="HeaderContainer">
          <LogoModel productId={sProductId}/>
          <div
            className="contact-page-close-button"
            onClick={handleClick}
          ></div>
        </div>
      </div>
      <div id="contact-page-body">
        <div className="contact-body-block">
          <div className={`contact-body-block-header product-font-${sProductId}`}>
            {t("ContactPageHeader1")}
          </div>
          <div className={`contact-page-tel-${sProductId}`}>
          <div className={`contact-body-block-text product-font-${sProductId}`}>
            {t("ContactPageNumber1")}
          </div>
          </div>
          <div className={`contact-page-tel2-${sProductId}`}>
          <div className="contact-body-block-text">
            {t("ContactPageNumber3")}
          </div>
          </div>
        </div>

        <div className={`contact-body-block-2-${locale} product-font-${sProductId}`}>
          <div className="contact-body-block-header-2">
            {t("ChatBotHeader")}
          </div>
          <div className={`contact-page-chatbot-button p-${sProductId}`}>
            <a
              className={`contact-page-chatbot-button p-${sProductId}`}
              href="https://anmal-skada.if.se/motorskada?claimType=claimLink" target="_blank" rel="noopener noreferrer"
            >
              {t("ChatBotButton")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ContactPage);
